/* input focus */
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
/*  */
.maincontainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.canceloverflow{
  overflow-x: hidden;
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formcontainer {
  max-width: 500px;
  position: relative;
}

.mainlogo {
  width: 200px;
}

.navbar {
  position: fixed;
  z-index: 95;
  top: 0px;
  /* overflow: hidden; */
  width: 100vw;
  height: 100px;
  background-color: white !important;
}
.navlinks{
  /* float: right; */
  margin-right: 50px;
}

/* hamburger */
.hamburger {
  /* position: fixed !important; */
  /* z-index: 1000; */
  width: 4rem;
  height: 4rem;
  top: 1rem;
  right: 3rem;
  cursor: pointer;
  padding: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 10px;
}

.line {
  width: 100%;
  height: 0.3rem;
  background-color: #5c5c5c;
  border-radius: 0.2rem;
  transition: all 0.5s ease-in-out;
}

/* .clicked .line-1 {
  transform: rotateZ(-405deg) translate(-0.4rem, 0.1rem);
  background-color: rgb(0, 0, 0);
}

.clicked .line-2 {
  opacity: 0;
  background-color: rgb(0, 0, 0);
}

.clicked .line-3 {
  transform: rotateZ(405deg) translate(-0.8rem, -0.6rem);
  background-color: rgb(0, 0, 0);
} */
/*  */

/* sidenav */
.menustar{
 display: none;
 /* visibility: hidden; */
}
.menustar img{
  rotate: 0deg;
  transition: 0.5s ease-in-out;

}
.sidenav {
  height: 0;
  width: 100%;
  border-radius: 0% 0% 100% 100%;
  position: fixed;
  /* position: relative; */
  z-index: 99;
  top: 0;
  right: 0;
  background: rgb(240, 231, 252);
  
  background: linear-gradient(0deg, rgba(240, 231, 252, 1) 0%, #4169E1 100%);
  /* background: linear-gradient(0deg, rgba(240, 231, 252, 1) 0%, rgba(205, 204, 254, 1) 100%); */
  overflow: hidden;
  /* padding-top: 60px;  */
  transition: 0.5s ease-in-out;
}

/* .sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 20px;
  margin-left: 50px;
  color: rgb(255, 255, 255);
  cursor: pointer;
} */
.closebtn{
  position: absolute;
  top: 0;
  right: 25px;
  margin-top: 30px;
  margin-left: 0px;
  font-size: 16px;
  color: #000000 !important;
}
.starimg{
  transition: .5s ease-in-out;
}
.starimg:hover{
  rotate: 360deg;
}
.Sidenavlinks {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 25px;
  color: rgb(0, 0, 0);
  display: block;
  transition: 0.3s;
  text-align: center;
}

.sidenavlinksactive {
  color: rgb(255, 255, 255) !important;
}
.sidenavopen{
  height: 0px;
  overflow: hidden;
  transition: .4s ease-in-out;
}
.arrow {
  border: solid rgba(0, 0, 0, 0.656);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 0px;
  margin-bottom: 5px;
  transition: .4s ease-in-out;
  transform: rotate(45deg);
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(225deg);
}

.couroselSection {
  margin-top: 100px;
  font-family: 'Times New Roman', Times, serif;
}
/* item animation */
.owl-item.active .couroseltitle{
  animation: fadeLeft 1.5s ease-in-out;
}
@keyframes fadeLeft {
  0% { 
    opacity: 0;
    transform: translateX(-100px); 
  }
  100% {
    opacity: 1;
     transform: translateX(0px);
     }
}
.owl-item.active .couroselsub{
  animation: fadeUp 1.5s ease-in-out;
}
@keyframes fadeUp {
  0% { 
    opacity: 0;
    transform: translateY(100px); 
  }
  100% {
    opacity: 1;
     transform: translateY(0px);
     }
}
.owl-item.active .rightarrowimg{
  animation: opacity 1.5s ease-in-out;
}


@keyframes opacity {
  0% { 
    opacity: 0;
    transform: translateX(100px); 
  }
  100% {
    opacity: 1;
     transform: translateX(0px);
     }
}



.rightarrowimg{
  width: 60px !important;
  float: right;
  /* margin-right: 40px; */
  opacity: 75%;
  transition: .4s;
}
.rightarrowimg:hover{
  opacity: 100%;
  transform: translateX(10px);
}
.topowl{
  height: 90vh;
  position: relative;
}

.item-outer {
  height: 90vh;
  position: relative;
  overflow: hidden;
}
.item-outer .img{
  object-fit: cover;
  width: auto;
  height: 90vh;
}
.owl-carousel .owl-stage {
  display: flex;
  position: relative;
}
.owl-dots{
  position: absolute;
  bottom: 50px;
  width: 100%;
}
.couroseltextctn{
  padding-left: 50px;
}

.item-inner {
  background-color: rgba(0, 0, 0, 0.377);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: white;
  overflow: hidden;
}

.item-inner h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-weight: 800;
}

.nav-link {
  color: #4169E1;
  font-size: 20px;
  transition: .5s;
}
.nav-link:hover{
  color: #4169E1;
}
.navlinksactive{
  color: #4169E1 !important;
  font-weight: bold;
}
.nav-link.show{
  color: #4169E1 !important;
}
.dropdown-item{
  width: 100%;
}
/* .navbar-inner{
    display: flex;
    justify-content: space-between !important;
  } */
.topsection {
  position: relative;
  /* padding-top: 100px; */
  min-height: 100vh;
  color: white !important;
  /* background: linear-gradient(-45deg, rgba(254, 242, 85, 1) 0%, #cdccfe 51%, #ff9380); */
  background: linear-gradient(-45deg, #4169E1 0%, #6687ea 51%, #4169E1);
  
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.toppara{
  font-weight: 500;
}
/* button 1 */
.button1 {
  padding: .75rem 1.25rem;
  border-radius: 10rem;
  background-color: #00000000;
  border:2px solid rgb(255, 255, 255);
  color: #ffffff;
  /* text-transform: uppercase; */
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: .3s;
}
.button1:hover{
  background-color: white;
  border:2px solid rgb(255, 255, 255);
  color: black;
  font-weight: bold;
}
.button2 {
  padding: .75rem 1.25rem;
  border-radius: 10rem;
  background-color: #00000000;
  border:2px solid rgb(0, 0, 0);
  color: #000000;
  /* text-transform: uppercase; */
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: .3s ease-in-out;
  text-decoration: none;
}
.button2:hover{
  background-color: rgb(0, 0, 0);
  border:2px solid rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-weight: bold;
}
/* Buttons */
.buttons {
  margin-top: 50px;
  text-align: center;
  border-radius: 30px;
}
.blob-btn {
  z-index: 1;
  position: relative;
  padding: 20px 46px;
  margin-bottom: 30px;
  text-align: center;
  /* text-transform: uppercase; */
  color: white;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 30px;
}
.blob-btn:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: 30px;
}
.blob-btn:after {
  content: "";
  z-index: -2;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 100%;
  height: 100%;
  transition: all 0.3s 0.2s;
  border-radius: 30px;
}
.blob-btn:hover {
  color: black;
  border-radius: 30px;
}
.blob-btn:hover:after {
  transition: all 0.3s;
  left: 0;
  top: 0;
  border-radius: 30px;
}
.blob-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: #ffffff00;
}
.blob-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url('#goo');
}
.blob-btn__blob {
  position: absolute;
  top: 2px;
  width: 25%;
  height: 100%;
  background: white;
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.7);
  transition: transform 0.45s;
}
/* @supports (filter: url('#goo')) 
{
  transform: translate3d(0, 150%, 0) scale(1.4);
} */
.blob-btn__blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}
.blob-btn__blob:nth-child(2) {
  left: 30%;
  transition-delay: 0.08s;
}
.blob-btn__blob:nth-child(3) {
  left: 60%;
  transition-delay: 0.16s;
}
.blob-btn__blob:nth-child(4) {
  left: 90%;
  transition-delay: 0.24s;
}
.blob-btn:hover .blob-btn__blob {
  transform: translateZ(0) scale(1.7);
}
/* @supports (filter: url('#goo')) {
  transform: translateZ(0) scale(1.4);
} */
.headtext{
  color: #2f5ce3;
  font-size: 50px;
  font-weight: bold;
}
/*  */

.topsection h1 {
  font-size: 60px;
  font-weight: bold;
}

.topsection p {
  font-size: 20px;
  text-align: justify;
}
.servicescontainer{
  margin-top: 50px;
}
.servicescontainer h3{
  font-size: 18px !important;
}
.servicescontainer p{
  font-size: 16px !important;
  text-align: center;
}
.homebgcolor{
  /* background: rgb(255,255,255);
  background: linear-gradient(185deg, rgba(255,255,255,1) 0%, #9bade1 23%, #4169E1 100%); */
}
.homecard {
  /* max-width: 700px; */
  width: 100%;
  min-height: 75vh;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 50px;
 padding: 30px;
  border-radius: 15px;
  color: black;
  text-align: justify;

}

.homecard h1 {
  font-size: 50px;
}

.homecard p {
  font-size: 20px;
  text-align: justify;
}
.homecardinside{
    /* background: radial-gradient(circle, rgb(174, 171, 254) 0%, rgb(202, 201, 255) 20.04%, rgba(254, 248, 6, 0) 50%); */
    background: radial-gradient(circle, #4169E1 0%, #c9d7ff 30.04%, rgba(254, 248, 6, 0) 55%);
}
/*  */

.categorycard {
  /* max-width: 700px; */
  width: 100%;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
 padding: 30px;
  border-radius: 15px;
  color: black;
  text-align: justify;

}

.categorycard h1 {
  font-size: 50px;
}

.categorycard p {
  font-size: 20px;
}
.categorycardinside{
    /* background: radial-gradient(circle, rgb(174, 171, 254) 0%, rgb(202, 201, 255) 20.04%, rgba(254, 248, 6, 0) 50%); */
    /* background: radial-gradient(circle, rgb(174, 171, 254) 0%, rgb(202, 201, 255) 30.04%, rgba(254, 248, 6, 0) 55%); */
}
/* background: radial-gradient(circle, #FFFE00 0%, #FEF806 48.04%, rgba(254, 248, 6, 0) 100%); */
/* contact */
.contacttopsection {
  position: relative;
  padding-top: 100px;
  min-height: 100vh;
  /* background: linear-gradient(-45deg,   #ff9380 0%, #cdccfe 51%, rgba(254, 242, 85, 1)); */
  background: linear-gradient(-45deg, #4169E1 0%, #b8b7ff 51%, #4169E1);
	background-size: 400% 400%;
  color: white;
	animation: gradient 30s ease infinite;
}
.contacttopsection p{
  text-align: justify;
}
.contactbtmsection{
  width: 100%;
  min-height: 100vh;
}
.socialmedia{
  gap: 20px;
  margin-top: 100px;

}
.socialmedia img{
  width: 50px;
  opacity: 60%;
  cursor: pointer;
}
.socialmedia img:hover{
  width: 50px;
  opacity: 100%;
  transition: .5s ease-in-out;
}
.socialmediafoot{
  gap: 20px;
}
.socialmediafoot img{
  width: 50px;
  opacity: 80%;
  cursor: pointer;
}
.socialmediafoot img:hover{
  width: 50px;
  opacity: 100%;
  transition: .5s ease-in-out;
}
textarea {
  resize: none;
}
/*  */
.loadercontain{
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.464);
  /* backdrop-filter: blur(1px); */
}
.loader {
  margin: auto;
  border: 3px solid #EAF0F6;
  border-radius: 50%;
  border-top: 3px solid #6759ff;
  width: 50px;
  height: 50px;
  animation: spinner 4s linear infinite;
}
.msgcontainer{
  background-color: rgba(255, 255, 255, 0.503);
  padding: 20px;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.scrolldown{
  position: absolute;
  bottom: 1rem;
  width: 100%;
  flex-direction: row;
}
.scrolldown img{
  scale: 20px;
}
.arrowimage{
  animation: jump 2s infinite;
}
/* .homeservicefluidcntn{
  mar
} */
.card{
  margin-top: 30px;
  max-width: 450px;
  margin-bottom: 30px;
  padding: 15px;
  height: 75vh;
  border-radius: 15px;
  transition: .4s ease-in-out;
  border-color: white;
  background-color: #4169E1;
  /* background: hsla(225, 73%, 57%, 1);

  background: linear-gradient(135deg, rgb(186, 221, 242) 0%, rgb(65, 105, 225) 100%); */
  color: white;
}
.card:hover{
  scale: .98;
}
.cardrowhome{
  width: 90%;
}
.card-body{
  height: 50vh;
}
.card-text{
  text-align: justify;
}
.cardimgcontainer{
    height: 550px;
    overflow: hidden;
}
.sectionheight{
  min-height: 100vh;
}
.homeaboutsection{
  /* margin-top: 50px; */
  min-height: 100vh;
  background-image: url(./assets/layered-waves-haikei.svg);
  background-size: cover;
}

@keyframes jump {
  0% { transform: translateY(-5px)  }
  50% { transform: translateY(10px) }
  100% { transform: translateY(-5px) }
  
}
.dropdown-menu{
  /* display: none !important; */
  /* position: fixed; */
  /* top: 7rem;
  right: 100rem !important; */
  padding-top: 20px;
  padding-bottom: 20px;
  width: 400px;
}
.dropdown-menu h3{
      font-size: 12px;
}
.dropdown-button:active {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.65) !important;
  }
.sitefooter{
  min-height: 350px;
  background-color: #4169E1;
  color: white;
  font-family: 'Calibri';
  font-size: 18px;
}
.copyrighttext{
  opacity: 80%;
}
.copyrightcontain{
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.666);
}
.sericespagecontain{
  min-height: 100vh;
  background-image: url(./assets/layered-waves-haikei.svg);
  background-size: cover;
}
.serviceftrctn{
  background-color: #4169E1;
  max-width: 500px;
  min-height: 200px;
  border-radius: 15px;
  margin-bottom: 30px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: .4s ease-in-out;
}
.serviceftrctn:hover{
  scale: .98;
}
.serviceftrctn p {
  margin-bottom: 0px;
  text-align: justify;
}

@media only screen and (max-width:600px) {
  .serviceftrctn p {
    margin-bottom: 0px;
    /* text-align: left; */
    /* font-size: 16px !important; */
    /* word-spacing: -2px; */
    /* word-break: break-all; */
  }
}
.tyrebelowctn{
  /* max-width: 800px; */
  max-width: 80%;
  min-height: 100px;
  border-radius: 15px;
  background-color: #597be2;
  margin-bottom: 20px;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.forminnercontainer input[type="text"]
{
    font-family: 'Calibri';
    font-weight: lighter;
}
.forminnercontainer input[type="text"]::placeholder {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
.footercontact p{
  animation: 4s ease-in-out;
  
}
.footercontact p:hover{
  text-decoration: underline;
  
}
.haulageconatin{
  background-color: #597BE2;
  border-radius: 15px;
  min-height: 5rem;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: white;
  animation: .4s ease-in-out;
}
@media only screen and (max-width:600px) {
  .categorycard h1 {
    margin-top: 20px;
    font-size: 30px;
  }
  .copyrighttext{
    font-size: 10px;
  }

  .topowl{
    height: 90vh;
  }
  
  .item-outer {
    height: 90vh;
  }
  .item-outer .img{
    height: 90vh;
  }
  .couroseltextctn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px !important;
    margin-bottom: 50px;
    padding-left: 0px;
    text-align: center !important;
  }
  .item-inner h1 {
    font-size: 38px;
  }

  .item-inner h5 {
    font-size: 18px;
  }
  .topsection{
    padding-top: 170px;
  }
  .arrowimage{
    display: none;
  }
  .homecard{
    margin-left: 10px;
    margin-right: 10px;
  }
  .msgcontainer{
    margin-left: 10px;
    margin-right: 10px;
  }
  .homecard h1 {
    font-size: 25px;
  }
  
  .homecard p {
    font-size: 14px;
  }
  .headtext{
    font-size: 25px;
  }
  .serviceftrctn h3 {
    font-size: 20px;
  }
  .serviceftrctn p {
    font-size: 14px;
  }
  .tyrebelowctn p{
    text-align: center !important;
  }

  
  
}
@media only screen and (max-width:769px) {
  .reversecolumn{
    display: flex;
    flex-direction: column-reverse;
  }
  .footaddresscontain h5,p{
    text-align: center !important;
}
}

@media only screen and (max-width:991px) {
  .navbar-toggler {
    display: none;
  }

  .navlinks {
    display: none !important;
  }
  .menustar{
    display: inline;
  }

  .hamburger {
    display: flex;
  }
}