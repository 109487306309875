body {
  margin: 0;
  /*  */
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h5{
  font-family: "Raleway", sans-serif;
}


/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

.raleway-<uniquifier> {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */
